<template>
    <b-modal no-close-on-backdrop no-close-on-esc id="form-perbaikan-modal">
        <b-form-group label="Judul" class="mb-2">
            <b-form-input type="text" autofocus placeholder="Ex: Perbaikan Aset x" v-model="form.judul"></b-form-input>
        </b-form-group>
        <b-form-group label="Tanggal" class="mb-2">
            <b-form-input type="date" v-model="form.tanggal"></b-form-input>
        </b-form-group>
        <b-form-group label="Keterangan (opsional)" class="mb-2">
            <b-form-textarea v-model="form.keterangan"></b-form-textarea>
        </b-form-group>

        <template #modal-footer>
            <b-button variant="primary" @click.prevent="submit" :disabled="!isFormValid">Simpan</b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BModal, BFormGroup, BFormInput, BFormTextarea, BButton} from 'bootstrap-vue'
    export default {
        components: {
            BModal, BFormGroup, BFormInput, BFormTextarea, BButton
        },
        data: () => ({
            form: {
                judul: null,
                tanggal: null,
                keterangan: null
            }
        }),
        computed: {
            isFormValid() {
                if(!this.form.judul || !this.form.tanggal) {
                    return false
                }

                return true
            }
        },
        watch: {
            "$store.state.asset.currentPerbaikan"(val) {
                if(val) {
                    if(val.judul) this.form.judul = val.judul
                    if(val.tanggal) this.form.tanggal = val.tanggal
                    if(val.keterangan) this.form.keterangan = val.keterangan
                }
                else {
                    this.form.judul = null
                    this.form.tanggal = this.getCurrentDate()
                    this.form.keterangan = null
                }
            }
        },
        methods: {
            submit() {
                if(!this.isFormValid) {
                    this.displayError({
                        message: 'Harap lengkapi Form Perbaikan'
                    })
                    return false
                }

                const payload = Object.assign({}, this.form)
                this.$emit('submit', payload)
            }
        },
        created() {
            this.form.tanggal = this.getCurrentDate()
        }
    }
</script>