var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": "form-perbaikan-modal"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isFormValid
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Judul"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "autofocus": "",
      "placeholder": "Ex: Perbaikan Aset x"
    },
    model: {
      value: _vm.form.judul,
      callback: function ($$v) {
        _vm.$set(_vm.form, "judul", $$v);
      },
      expression: "form.judul"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Tanggal"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Keterangan (opsional)"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.form.keterangan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }